

.btn-variant(@bg: @btn-bg, @color: @btn-color, @border: @btn-border) {
	color: @color;
	background-color: @bg;
	border-color: @border;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: @color;
		background-color: darken(@bg, 10%);
		border-color: darken(@border, 12%);
	}
	&:active,
	&.active {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: @bg;
			border-color: @border;
		}
	}
}

.button-size(@padding-vertical: @padding-base-vertical, @padding-horizontal: @padding-base-horizontal, @font-size: @font-size-base, @line-height: @line-height-base, @border-radius: @border-radius-base) {
	padding: @padding-vertical @padding-horizontal;
	font-size: @font-size;
	line-height: @line-height;
	border-radius: @border-radius;
}

.btn,
button,
input[type=button],
input[type=submit] {

	display: inline-block;
	margin-bottom: 0;
	font-weight: @btn-font-weight;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	.transition(~"background-color linear .15s, border-color linear .15s, color linear .15s");
	.user-select(none);
	.btn-variant(@btn-bg, @btn-color, @btn-border);
	.button-size(@padding-base-vertical, @padding-base-horizontal, @font-size-base, @line-height-base, @border-radius-base);

	&:focus {
		outline: none;
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		color: @btn-color;
		background-color: darken(@btn-bg, 10%);
		border-color: darken(@btn-border, 12%);
	}

	&:active,
	&.active {
		outline: none;
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		pointer-events: none;
		.opacity(.65);
		box-shadow: none;
	}

	&.primary { .btn-variant(@brand-dark-blue, #fff, darken(@brand-dark-blue, 4%)); }
	&.success { .btn-variant(@brand-success, #fff, darken(@brand-success, 4%)); }
	&.info    { .btn-variant(@brand-info, #fff, darken(@brand-info, 4%)); }
	&.warning { .btn-variant(@brand-warning, #fff, darken(@brand-warning, 4%)); }
	&.danger  { .btn-variant(@brand-danger, #fff, darken(@brand-danger, 4%)); }

	&.lg { .button-size(@padding-lg-vertical; @padding-lg-horizontal; @font-size-lg; @line-height-lg; @border-radius-lg); }
	&.sm { .button-size(@padding-sm-vertical; @padding-sm-horizontal; @font-size-sm; @line-height-sm; @border-radius-sm); }
	&.xs { .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-sm; @line-height-sm; @border-radius-sm); }

	&.link {
		.btn-variant(transparent, @link-color, transparent);
		font-weight: @font-weight-base;
		border-radius: 0;
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: transparent;
			border-color: transparent;
		}
		&:hover,
		&:focus {
			color: @link-hover-color;
		}
		&:active,
		&.active {
			box-shadow: none;
		}
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: @text-muted;
			}
		}
	}

}

input[type=submit],
button[type=submit] {
	&:extend(.btn.primary all);
}