

// Base Styles

.flex-viewport {
	max-height: 2000px;
	.transition(all 1s ease);
	.loading & {
		max-height: 300px;
	}
}

.flexslider {
	position: relative;
	zoom: 1;
	.slides {
		&:extend(.clearfix all, .list-unstyled);
		margin: 0;
		zoom: 1;
		> li {
			display: none;
			-webkit-backface-visibility: hidden;
		}
		img {
			width: 100%;
			display: block;
		}
	}
}

.carousel {
	li {
		margin-right: 5px;
	}
}


// Direction Navigation

.flex-direction-nav {
	&:extend(.list-unstyled);
	margin: 0;
	*height: 0;
	a {
		.fa;
		.fa-fw;
		text-decoration: none;
		display: block;
		.square(60px);
		font-size: 60px;
		margin: -30px 0 0;
		position: absolute;
		top: 50%;
		z-index: 10;
		overflow: hidden;
		text-indent: -99999px;
		.opacity(1);
		cursor: pointer;
		color: #fff;
		line-height: 1;
		text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		.transition(all .3s ease);
		.flexslider:hover & {
			.opacity(0.7);
		}
		&:hover {
			.opacity(1);
			opacity: 1 !important;
		}
		&:before {
			.fa;
			position: absolute;
			left: 0;
			top: 0;
			.square(100%);
			text-align: center;
			text-indent: 0;
		}
		&.flex-prev {
			left: 0;
			&:before { content: @fa-var-angle-left; }
		}
		&.flex-next {
			right: 0;
			&:before { content: @fa-var-angle-right; }
		}
		@media (min-width: @screen-sm) {
			.opacity(0);
		}
	}
	.flex-disabled {
		.opacity(0);
		opacity: 0 !important;
		cursor: default;
	}
}


// Pause/Play

.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	.opacity(0.8);
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
	&:hover {
		.opacity(1);
	}
	&.flex-play {

	}
}

// Control Navigation

.flex-control-nav {
	&:extend(.list-unstyled);
	margin: 0;
	width: 100%;
	position: absolute;
	bottom: 20px;
	text-align: center;
	z-index: 10;
	li {
		margin: 0 6px;
		display: inline-block;
		zoom: 1;
		*display: inline;
		a {
			.square(12px);
			display: block;
			background-color: #ccc;
			background-color: rgba(255, 255, 255, 0.5);
			cursor: pointer;
			text-indent: -9999px;
			border-radius: 20px;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
			.transition(all .3s ease);
			&:hover {
				background-color: #eee;
				background-color: rgba(255, 255, 255, 0.7);
			}
			&.flex-active {
				background-color: #fff;
				cursor: default;
			}
		}
	}
}

.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
	li {
		width: 25%;
		float: left;
		margin: 0;
	}
	img {
		width: 100%;
		display: block;
		.opacity(0.7);
		cursor: pointer;
		&:hover {
			.opacity(1);
		}
		&.flex-active {
			.opacity(1);
			cursor: default;
		}
	}
}