

/*	Colors
---------------------------------------------------------*/

@brand-blue:                           #0081CA;
@brand-navy-blue:                      #004871;
@brand-dark-blue:                      #2F3F44;

@brand-primary:                        @brand-blue;
@brand-success:                        #5cb85c;
@brand-info:                           #5bc0de;
@brand-warning:                        #f0ad4e;
@brand-danger:                         #d9534f;


/*	Scaffolding
---------------------------------------------------------*/

@body-bg:                              #fff;
@sidebar-bg:                           @brand-dark-blue;
@header-bg:                            @brand-dark-blue;

@text-color:                           #333;
@text-muted:                           #aaa;

@link-color:                           @brand-primary;
@link-hover-color:                     darken(@link-color, 15%);

@hr-border:                            #ccc;
@abbr-border-color:                    #ccc;

@sidebar-width-xs:                     250px;
@sidebar-width-sm:                     250px;
@sidebar-width-md:                     280px;
@sidebar-width-lg:                     300px;

@default-grid-gutter:                  30px;
@default-grid-columns:                 12;


/*	Typography
---------------------------------------------------------*/

@font-family-sans-serif:               'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-serif:                    Georgia, 'Times New Roman', Times, serif;
@font-family-monospace:                Menlo, Monaco, Consolas, 'Courier New', monospace;
@font-family-base:                     @font-family-sans-serif;

@font-weight-base:                     300;

@font-size-base:                       15px;
@font-size-lg:                         18px;
@font-size-sm:                         12px;

@font-size-h1:                         46px;
@font-size-h2:                         32px;
@font-size-h3:                         24px;
@font-size-h4:                         18px;
@font-size-h5:                         14px;
@font-size-h6:                         12px;

@line-height-base:                     1.4;
@line-height-lg:                       1.6;
@line-height-sm:                       1.2;
@line-height-computed:                 floor(@line-height-base * @font-size-base);

@headings-font-family:                 inherit;
@headings-font-weight:                 300;
@headings-line-height:                 @line-height-sm;
@headings-color:                       inherit;

@blockquote-font-family:               inherit;
@blockquote-font-weight:               300;
@blockquote-font-size:                 floor(@font-size-base * 1.25);
@blockquote-line-height:               @line-height-base;
@blockquote-border-color:              #eee;

@code-color:                           #c7254e;
@code-bg:                              #f9f2f4;

@kbd-color:                            #fff;
@kbd-bg:                               #333;

@pre-bg:                               #f5f5f5;
@pre-color:                            #666;
@pre-border-color:                     #ccc;
@pre-scrollable-max-height:            340px;


/*	Core Components
---------------------------------------------------------*/

@padding-base-vertical:                6px;
@padding-base-horizontal:              12px;

@padding-lg-vertical:                  10px;
@padding-lg-horizontal:                16px;

@padding-sm-vertical:                  5px;
@padding-sm-horizontal:                10px;

@padding-xs-vertical:                  1px;
@padding-xs-horizontal:                5px;

@border-radius-base:                   3px;
@border-radius-lg:                     4px;
@border-radius-sm:                     2px;

@caret-width-base:                     4px;
@caret-width-lg:                       5px;


/*	Tables
---------------------------------------------------------*/

@table-cell-padding:                   8px;
@table-condensed-cell-padding:         5px;

@table-bg:                             transparent;
@table-bg-accent:                      #f9f9f9;
@table-bg-hover:                       #f5f5f5;
@table-bg-active:                      @table-bg-hover;

@table-border-color:                   #ddd;


/*	Buttons
---------------------------------------------------------*/

@btn-bg:                               #fff;
@btn-color:                            @text-color;
@btn-border:                           #ccc;
@btn-font-weight:                      @font-weight-base;


/*	Forms
---------------------------------------------------------*/

@input-bg:                             #fff;
@input-bg-disabled:                    #ddd;

@input-color:                          @text-color;
@input-border:                         #ccc;
@input-border-radius:                  @border-radius-base;
@input-border-focus:                   @brand-blue;

@input-color-placeholder:              #aaa;

@input-height-base:                    (@line-height-computed + (@padding-base-vertical * 2) + 2);
@input-height-lg:                      (ceil(@font-size-lg * @line-height-lg) + (@padding-lg-vertical * 2) + 2);
@input-height-sm:                      (floor(@font-size-sm * @line-height-sm) + (@padding-sm-vertical * 2) + 2);

@label-font-weight:                    700;

@legend-color:                         #aaa;
@legend-border-color:                  #ddd;


/*	Z-Index
---------------------------------------------------------*/

@zindex-dropdown:                      1000;
@zindex-popover:                       1060;
@zindex-tooltip:                       1070;
@zindex-modal-background:              1040;
@zindex-modal:                         1050;


/*	Media Query Breakpoints
---------------------------------------------------------*/

@screen-xs:                            480px;
@screen-xs-min:                        @screen-xs;
@screen-xs-max:                        (@screen-sm-min - 1);

@screen-sm:                            768px;
@screen-sm-min:                        @screen-sm;
@screen-sm-max:                        (@screen-md-min - 1);

@screen-md:                            992px;
@screen-md-min:                        @screen-md;
@screen-md-max:                        (@screen-lg-min - 1);

@screen-lg:                            1200px;
@screen-lg-min:                        @screen-lg;


/*	Containers
---------------------------------------------------------*/

@container-xs-gutter:                  15px;

@container-sm:                         1200px;
@container-sm-gutter:                  15px;

@container-md:                         1200px;
@container-md-gutter:                  20px;

@container-lg:                         1200px;
@container-lg-gutter:                  30px;


/*	Transitions
---------------------------------------------------------*/

@mobile-sidebar-transition-duration:      0.2s;