

// Core variables and mixins
@import '_fonts.less';
@import '_variables.less';
@import '_mixins.less';
@import '_icons.less';

// Libraries
@import "../../library/font-awesome/less/font-awesome.less";
@fa-font-path: '../library/font-awesome/fonts';

// Core CSS
@import '_normalize.less';
@import '_scaffolding.less';
@import '_type.less';
@import '_tables.less';
@import '_forms.less';
@import '_buttons.less';
@import '_print.less';

// Core Components
@import '_alerts.less';
@import '_gravity-forms.less';
@import '_flexslider.less';



/*	Core
---------------------------------------------------------*/


// Posts

.entry-title,
.section-title {
	// margin-top: 0;
}

.entry-meta {
	font-size: @font-size-sm;
	color: @text-muted;
	margin-bottom: (@line-height-computed / 2);
	.entry-title + & {
		margin-top: -(@line-height-computed / 3);
	}
	p {
		margin-bottom: 0;
	}
	a {
		color: @text-muted;
		&:hover {
			color: @link-color;
		}
	}
	&.meta-list {
		overflow: hidden;
		ul {
			.list-unstyled;
			.clearfix;
			margin: 0;
			margin-left: -17px;
			li {
				float: left;
				padding-left: 8px;
				margin-left: 8px;
				border-left: 1px solid #e0e0e0;
				&.entry-author,
				&.entry-date,
				&.entry-comments {
					a:before {
						.fa;
						margin-right: 4px;
					}
				}
				&.entry-author a:before { content: @fa-var-user; }
				&.entry-date a:before { content: @fa-var-clock-o; }
				&.entry-comments a:before { content: @fa-var-comments; }
			}
		}
	}
}



/*	Components
---------------------------------------------------------*/


// Actions Navigation
nav.actions {
	margin-bottom: (@line-height-computed / 2);
	a {
		.btn;
		.btn.sm;
	}
}


// Search and Post Password Forms
.search-form,
.post-password-form {
	margin-bottom: (@line-height-computed / 2);
	display: table;
	max-width: 300px;
	label,
	.submit-wrap {
		display: table-cell;
	}
	label {
		vertical-align: middle;
		margin: 0;
	}
	.submit-wrap {
		width: 1%;
	}
	input[type=search],
	input[type=password] {
		.border-right-radius(0);
	}
	button {
		.border-left-radius(0);
		margin-left: -1px;
	}
}
.search-form {
	label {
		position: relative;
		&:before {
			.fa;
			content: @fa-var-search;
			position: absolute;
			left: 10px;
			top: 50%;
			font-size: 15px;
			margin-top: -9px;
			color: @text-color;
		}
	}
	input[type=search] {
		.border-left-radius(100px);
		padding-left: 32px;
	}
	button {
		.border-right-radius(100px);
	}
}


// Content and Comment Navigation
.content-navigation,
.comment-navigation {
	ul {
		&:extend(.list-unstyled);
	}
}
.content-navigation {
	margin: @line-height-computed 0;
}
#content-nav-above {
	margin-top: 0;
}


// Caption
.wp-caption {
	margin-bottom: (@line-height-computed / 2);
	max-width: 100%;
}
.wp-caption-text {
	font-size: @font-size-sm;
	margin: (@line-height-computed / 4) 0 0;
}


// Galleries
.gallery {
	@gutter: 10px;
	.row(0, @gutter);
	margin-bottom: (@line-height-computed / 2);
	.gallery-item {
		.col(0, percentage(1 / 4), @gutter);
		margin-bottom: @gutter;
		// &:nth-child(4n+1) {
		// 	clear: left;
		// }
		img {
			width: 100%;
		}
	}
	.gallery-caption {
		display: none;
	}
	&.gallery-columns-1 .gallery-item { .col(0, percentage(1 / 1), @gutter); &:nth-child(1n + 1) { clear: left; } }
	&.gallery-columns-2 .gallery-item { .col(0, percentage(1 / 2), @gutter); &:nth-child(2n + 1) { clear: left; } }
	&.gallery-columns-3 .gallery-item { .col(0, percentage(1 / 3), @gutter); &:nth-child(3n + 1) { clear: left; } }
	&.gallery-columns-4 .gallery-item { .col(0, percentage(1 / 4), @gutter); &:nth-child(4n + 1) { clear: left; } }
	&.gallery-columns-5 .gallery-item { .col(0, percentage(1 / 5), @gutter); &:nth-child(5n + 1) { clear: left; } }
	&.gallery-columns-6 .gallery-item { .col(0, percentage(1 / 6), @gutter); &:nth-child(6n + 1) { clear: left; } }
	&.gallery-columns-7 .gallery-item { .col(0, percentage(1 / 7), @gutter); &:nth-child(7n + 1) { clear: left; } }
	&.gallery-columns-8 .gallery-item { .col(0, percentage(1 / 8), @gutter); &:nth-child(8n + 1) { clear: left; } }
	&.gallery-columns-9 .gallery-item { .col(0, percentage(1 / 9), @gutter); &:nth-child(9n + 1) { clear: left; } }
}


// Pagination
nav.pagination {
	&:extend(.clearfix all);
	margin-bottom: (@line-height-computed / 2);
	ul {
		&:extend(.list-unstyled);
		display: table;
		margin: 0;
	}
	li {
		display: table-cell;
		&:first-child > a {
			margin-left: 0;
			.border-left-radius(@border-radius-base);
		}
		&:last-child > a {
			.border-right-radius(@border-radius-base);
		}
		&:hover > a,
		&:active > a,
		&.current > a {
			z-index: 1;
		}
		&.current > a {
			&:extend(.btn.active all);
		}
		&.prev > a,
		&.next > a {
			&:before {
				.fa;
				line-height: @line-height-base;
			}
			span { display: none; }
		}
		&.prev > a:before { content: @fa-var-angle-left; }
		&.next > a:before { content: @fa-var-angle-right; }
		&.no-link > a {
			&:extend(.btn.disabled all);
		}
		> a {
			&:extend(.btn all);
			margin-left: -1px;
			border-radius: 0;
			position: relative;
			display: block;
		}
	}
}

a.suggest-link {
	float: right;
}

// Resource List
ul.resource-list {
	.list-unstyled;
	font-size: @font-size-sm;
	color: @text-muted;
	li {
		margin-bottom: (@line-height-computed / 2);
		position: relative;
		padding-left: 32px;
		min-height: 24px;
		a {
			color: @text-color;
			&:hover {
				color: @link-color;
			}
		}
		.icon {
			.fa;
			position: absolute;
			left: 0;
			top: 0;
			font-size: 24px;
			&:before {
				content: @fa-var-file-o;
			}
		}
		h4, p {
			margin-bottom: 0;
		}
		h4 {
			font-size: 15px;
			line-height: @line-height-base;
			span.file-size {
				font-size: 10px;
				color: #ccc;
				margin-left: 4px;
			}
		}
		&.mime-type-image-jpeg .icon:before,
		&.mime-type-image-png .icon:before,
		&.mime-type-image-gif .icon:before {
			content: @fa-var-file-image-o;
		}
		&.mime-type-video-mpeg .icon:before,
		&.mime-type-video-mp4 .icon:before,
		&.mime-type-video-quicktime .icon:before {
			content: @fa-var-file-video-o;
		}
		&.mime-type-text-csv .icon:before,
		&.mime-type-text-plain .icon:before {
			content: @fa-var-file-text-o;
		}
		&.mime-type-text-xml .icon:before {
			content: @fa-var-file-code-o;
		}
		&.mime-type-application-pdf .icon:before {
			content: @fa-var-file-pdf-o;
		}
		&.mime-type-application-zip .icon:before {
			content: @fa-var-file-archive-o;
		}
	}
}

// Member Profile Form
.member-profile-form {
	@gutter: 20px;
	.field {
		.clearfix;
		&.type-repeater {
			> ul.entries {
				.list-unstyled;
				> li.entry {
					margin-bottom: (@line-height-computed / 2);
					padding: 12px 12px 4px;
					position: relative;
					background-color: #f3f3f3;
					@media (min-width: @screen-sm) {
						padding-left: 0;
						padding-right: 0;
						.field > .field-label {
							padding-left: 12px;
						}
						.field > .field-input {
							padding-right: 12px;
						}
					}
					&.tpl {
						display: none;
					}
					&:hover button.remove-entry {
						display: block;
					}
					button.remove-entry {
						position: absolute;
						right: 8px;
						top: 4px;
						border: none;
						padding: 0;
						color: #ccc;
						line-height: 1;
						font-size: 24px;
						background-color: transparent;
						@media (min-width: @screen-sm) {
							right: auto;
							left: 8px;
							display: none;
						}
						&:hover {
							color: #aaa;
						}
						&:active {
							box-shadow: none;
						}
					}
				}
			}
			button.add-entry {
				.btn.sm;
			}
		}
		&.type-date,
		&.type-password {
			.field-input input {
				max-width: 250px;
			}
		}
		&.type-password {
			.field-input input {
				margin-top: 2px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
		&.has-error {
			&:extend(.field.error all);
		}
		> .field-label {
			@media (min-width: @screen-sm) {
				float: left;
				width: 150px;
				text-align: right;
				padding-top: 6px;
			}
			@media (min-width: @screen-md) {
				width: 180px;
			}
		}
		> .field-input {
			@media (min-width: @screen-sm) {
				margin-left: (150px + @gutter);
			}
			@media (min-width: @screen-md) {
				margin-left: (180px + @gutter);
			}
		}
		p.errors {
			color: @brand-danger;
			font-size: @font-size-sm;
			margin-top: (@line-height-computed / 4);
			margin-bottom: 0;
		}
	}
	.form-footer {
		margin-top: @line-height-computed;
		margin-bottom: @line-height-computed;
		button[type=submit] {
			.btn.lg;
		}
	}
}

// Member Details
ul.member-details {
	@gutter: 20px;
	.list-unstyled;
	> li {
		.clearfix;
		margin-bottom: 6px;
		&.repeater-entries {
			margin-left: -(@gutter / 2);
			margin-right: -(@gutter / 2);
			margin-bottom: 0;
			@media (min-width: @screen-sm) {
				margin-left: 0;
				margin-right: 0;
			}
			> ul {
				.list-unstyled;
				> li {
					margin-bottom: 6px;
					padding: 8px 10px 4px;
					background-color: #f3f3f3;
					@media (min-width: @screen-sm) {
						padding-left: 0;
						padding-right: 0;
						> ul.member-details > li > .label {
							padding-left: 10px;
						}
						> ul.member-details > li > .value {
							padding-right: 10px;
						}
					}
				}
			}
		}
		> .label,
		> .value {
			display: block;
		}
		> .label {
			font-size: @font-size-sm;
			@media (min-width: @screen-sm) {
				float: left;
				width: 150px;
				text-align: right;
				padding-top: 4px;
			}
			@media (min-width: @screen-md) {
				width: 180px;
			}
		}
		> .value {
			@media (min-width: @screen-sm) {
				margin-left: (150px + @gutter);
			}
			@media (min-width: @screen-md) {
				margin-left: (180px + @gutter);
			}
		}
	}
}

// Address Book
table.address-book {
	thead {
		display: none;
		@media (min-width: @screen-sm) {
			display: table-header-group;
		}
		th {
			border-bottom: none;
			background-color: tint(@brand-navy-blue, 65%);
			color: #fff;
			font-size: @font-size-sm;
			text-transform: uppercase;
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}
	tbody {
		tr {
			display: block;
			background-color: tint(@brand-navy-blue, 94%);
			padding: @table-cell-padding;
			.clearfix;
			@media (min-width: @screen-sm) {
				display: table-row;
				padding: 0;
				background-color: transparent;
				&:before, &:after { display: none; }
			}
			&:nth-child(even) {
				background-color: tint(@brand-navy-blue, 88%);
				@media (min-width: @screen-sm) {
					background-color: transparent;
					td {
						background-color: tint(@brand-navy-blue, 88%);
					}
				}
			}
		}
		td {
			display: block;
			border-top: none;
			padding: 0;
			margin-left: 50px;
			@media (min-width: @screen-sm) {
				display: table-cell;
				background-color: tint(@brand-navy-blue, 94%);
				margin-left: 0;
				padding: @table-cell-padding;
			}
			&.photo {
				float: left;
				width: 40px;
				margin-left: 0;
				@media (min-width: @screen-sm) {
					float: none;
					width: 40px + (@table-cell-padding * 2);
					img {
						width: 40px;
					}
				}
				img {
					border-radius: 100%;
				}
			}
			&.name {
				margin-bottom: 4px;
				@media (min-width: @screen-sm) {
					margin-bottom: 0;
				}
			}
			&.address,
			&.phone,
			&.email {
				font-size: @font-size-sm;
			}
			&.address {
				display: none;
				@media (min-width: @screen-sm) {
					display: table-cell;
				}
			}
			&.phone {
				strong {
					text-transform: uppercase;
					font-size: 10px;
					min-width: 35px;
					display: inline-block;
				}
				a {
					white-space: nowrap;
				}
			}
		}
	}
	a {
		color: @text-color;
		&:hover {
			color: @link-color;
		}
	}
}

// Family Tree
.family-tree-embed {
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
}
.family-tree-chart {
	@member-width: 100px;
	@member-height: 48px;
	@h-gutter: 30px;
	@v-gutter: 10px;
	@line-color: #aaa;
	.clearfix;
	clear: both;
	overflow: auto;
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
	ul {
		.list-unstyled;
		margin: 0;
	}
	.member {
		display: block;
		width: @member-width;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			.size((@h-gutter / 3), 0);
			border-top: 1px solid @line-color;
			right: 100%;
			top: (@member-height / 2);
		}
		> .member-info {
			display: table-cell;
			vertical-align: middle;
			font-size: 12px;
			line-height: @line-height-sm;
			text-align: center;
			.size(@member-width, @member-height);
			padding: 0 4px;
			border: 1px solid #aaa;
			background-color: #fafafa;
			position: relative;
			z-index: 1;
			color: @text-color;
			overflow: hidden;
			line-height: 1.1;
			span {
				display: inline-block;
				max-width: (@member-width - 10px);
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				vertical-align: middle;
			}
		}
		&.gender-male > .member-info {
			background-color: #d7e3ff;
			border-color: #8F9FC2;
		}
		&.gender-female > .member-info {
			background-color: #f8d7ff;
			border-color: #B086BA;
		}
		&.faded {
			.opacity(.4);
		}
	}
	ul.children {
		float: left;
		position: relative;
		margin-left: @h-gutter;
		margin-top: -(@member-height + @v-gutter);
		&:before {
			content: '';
			display: block;
			position: absolute;
			.size((@h-gutter / 3), 0);
			border-top: 1px solid @line-color;
			left: -(@h-gutter / 3) * 2;
			top: @member-height + (@v-gutter / 2);
		}
		> li {
			float: left;
			clear: left;
			margin-top: @v-gutter;
			position: relative;
			&.no-unions.only-child {
				margin-top: (@member-height / 2) + (@v-gutter / 2);
				&:before {
					display: none;
				}
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				border-left: 1px solid @line-color;
				left: -(@h-gutter / 3);
				top: (@member-height / 2);
				bottom: -(@member-height / 2) - @v-gutter - 1px;
			}
			&:first-child {
				margin-top: 0;
				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 0;
					border-left: 1px solid @line-color;
					left: -(@h-gutter / 3);
					top: (@member-height / 2);
					height: (@member-height / 2) + (@v-gutter / 2) + 1px;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
	ul.unions {
		position: relative;
		float: left;
		&:before {
			content: '';
			display: block;
			position: absolute;
			.size((@h-gutter / 3), 0);
			border-top: 1px solid @line-color;
			left: @member-width;
			top: -(@member-height / 2);
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			border-left: 1px solid @line-color;
			left: @member-width + (@h-gutter / 3);
			top: -(@member-height / 2);
			height: @member-height + @v-gutter + 1px;
		}
		> li {
			margin-top: @v-gutter;
			float: left;
			clear: left;
			position: relative;
			&:before {
				content: '';
				display: block;
				position: absolute;
				.size((@h-gutter / 3), 0);
				border-top: 1px solid @line-color;
				left: @member-width;
				top: (@member-height / 2);
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				border-left: 1px solid @line-color;
				left: @member-width + (@h-gutter / 3);
				top: (@member-height / 2);
				bottom: -(@member-height / 2) - @v-gutter - 1px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			+ li {
				margin-top: @member-height + (@v-gutter * 2);
				&:after,
				&:last-child:after {
					display: block;
					top: -(@member-height / 2) - @v-gutter;
					bottom: auto;
					height: @member-height + @v-gutter + 1px;
				}
				&.no-children {
					margin-top: @v-gutter;
				}
			}
			> .member {
				float: left;
				&:before {
					display: none;
				}
			}
		}
	}
	> ul.children {
		margin-left: 0;
		margin-top: 0;
		&:before {
			display: none;
		}
		> li {
			> .member:before {
				display: none;
			}
		}
	}
}

// Event Calendar
.equinox {
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
	.calendar-header {
		background-color: @brand-blue;
	}
	.calendar-actions {
		button {
			.btn.primary;
		}
	}
	.month-weeks {
		border-color: #ccc;
	}
	.days-container {
		.day {
			border-color: #ccc;
		}
	}
	.date.current-date:before {
		border-color: #A5CFDD;
	}
	.event {
		border: 1px solid @brand-blue;
		border-left-width: 4px;
		padding: 6px 6px;
		.start-time {
			display: block;
		}
	}
	@media (max-width: 600px) {
		.month-label {
			float: none;
			text-align: left;
		}
		.month-weeks {
			min-height: 30px;
		}
		.week-events {
			display: none;
		}
		.days-container {
			display: block;
			.day {
				display: none;
				width: 100%;
				&.has-events {
					display: block;
				}
			}
		}
		.date-events {
			min-height: 0;
		}
	}
}



/*	Sidebar
---------------------------------------------------------*/

#sidebar-main,
#sidebar-footer {
	background-color: shade(@sidebar-bg, 50%);
	color: tint(@sidebar-bg, 50%);
	position: fixed;
	width: @sidebar-width-xs;
	visibility: hidden;
	left: 100%;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	@media (min-width: @screen-sm) {
		width: @sidebar-width-sm;
		visibility: visible;
		z-index: 100;
		left: 0;
		background-color: @sidebar-bg;
	}
	@media (min-width: @screen-md) {
		width: @sidebar-width-md;
	}
	@media (min-width: @screen-lg) {
		width: @sidebar-width-lg;
	}
	> .inner {
		.translate3d(-(@sidebar-width-xs / 2), 0, 0);
		@media (min-width: @screen-sm) {
			.translate3d(0, 0, 0);
		}
	}
	a {
		color: tint(@sidebar-bg, 70%);
		&:hover {
			color: #fff;
		}
	}
}

#sidebar-main {
	top: 0;
	body.admin-bar & {
		padding-top: 32px;
		@media (max-width: 782px) {
			padding-top: 46px;
		}
	}
	> .inner {
		padding-left: @container-xs-gutter;
		padding-right: @container-xs-gutter;
		padding-top: (@line-height-computed / 2);
		@media (min-width: @screen-sm) {
			padding-left: @container-sm-gutter;
			padding-right: @container-sm-gutter;
			padding-top: 0;
		}
		@media (min-width: @screen-md) {
			padding-left: @container-md-gutter;
			padding-right: @container-md-gutter;
		}
	}

	.site-branding {
		display: none;
		max-width: 200px;
		margin: 20px auto;
		@media (min-width: @screen-sm) {
			display: block;
		}
		@media (min-width: @screen-lg) {
			margin: 30px auto;
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	.search-form {
		display: block;
		@media (min-width: @screen-sm) {
			margin-bottom: 15px;
		}
		label {
			display: block;
			&:before {
				color: tint(@sidebar-bg, 70%);
			}
		}
		.submit-wrap {
			display: none;
		}
		input[type=search] {
			border-radius: 100px;
			background-color: shade(@sidebar-bg, 60%);
			border-color: shade(@sidebar-bg, 75%);
			color: tint(@sidebar-bg, 80%);
			.placeholder(tint(@sidebar-bg, 20%));
			&:focus {
				border-color: tint(@sidebar-bg, 40%);
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px fadeout(tint(@sidebar-bg, 40%), 20%);
			}
			@media (min-width: @screen-sm) {
				background-color: shade(@sidebar-bg, 10%);
				border-color: shade(@sidebar-bg, 25%);
			}
		}
	}

}

#sidebar-navigation {
	font-size: @font-size-lg;
	margin-left: -@container-xs-gutter;
	margin-right: -@container-xs-gutter;
	margin-bottom: (@line-height-computed / 2);
	@media (min-width: @screen-sm) {
		margin-left: -@container-sm-gutter;
		margin-right: -@container-sm-gutter;
	}
	@media (min-width: @screen-md) {
		margin-left: -@container-md-gutter;
		margin-right: -@container-md-gutter;
	}
	ul {
		&:extend(.list-unstyled);
		margin: 0;
		&.menu {
			> li.current-menu-item,
			> li.current-menu-ancestor {
				> a {
					color: #fff;
					background-color: shade(@sidebar-bg, 30%);
					@media (min-width: @screen-sm) {
						background-color: tint(@sidebar-bg, 10%);
					}
				}
			}
		}
	}
	li.current-menu-item > .sub-menu-container,
	li.current-menu-ancestor > .sub-menu-container {
		height: auto;
	}
	li.current-menu-item > a,
	li.current-menu-ancestor > a {
		color: #fff;
	}
	a {
		display: block;
		padding: 4px @container-xs-gutter;
		@media (min-width: @screen-sm) {
			padding-left: @container-sm-gutter;
			padding-right: @container-sm-gutter;
		}
		@media (min-width: @screen-md) {
			padding-left: @container-md-gutter;
			padding-right: @container-md-gutter;
		}
	}
	.sub-menu-container {
		font-size: @font-size-base;
		height: 0;
		overflow: hidden;
		background-color: shade(@sidebar-bg, 60%);
		@media (min-width: @screen-sm) {
			background-color: shade(@sidebar-bg, 10%);
		}
		ul {
			padding-top: 6px;
			padding-bottom: 6px;
		}
		a {
			color: tint(@sidebar-bg, 50%);
			&:hover {
				color: #fff;
			}
		}
	}
}

#sidebar-footer {
	background-color: shade(@sidebar-bg, 10%);
	ul.notifications,
	ul.legal {
		.list-unstyled;
		margin-bottom: 0;
	}
	ul.notifications li a,
	.user,
	ul.legal {
		padding-left: @container-xs-gutter;
		padding-right: @container-xs-gutter;
		@media (min-width: @screen-sm) {
			padding-left: @container-sm-gutter;
			padding-right: @container-sm-gutter;
		}
		@media (min-width: @screen-md) {
			padding-left: @container-md-gutter;
			padding-right: @container-md-gutter;
		}
	}

	ul.notifications {
		a {
			color: shade(@sidebar-bg, 40%);
			display: block;
			background-color: tint(@sidebar-bg, 60%);
			padding-top: 6px;
			padding-bottom: 6px;
			.transition(background-color 0.15s linear);
			&:hover {
				background-color: tint(@sidebar-bg, 70%);
			}
			span.badge {
				display: inline-block;
				background-color: #fff;
				border-radius: 100px;
				font-weight: bold;
				font-size: @font-size-sm;
				padding: 1px 8px;
				position: relative;
				top: -2px;
				margin-right: 4px;
			}
		}
	}

	.user {
		padding-top: 15px;
		padding-bottom: 15px;
		.clearfix;
		.avatar {
			display: block;
			.square(40px);
			// background-color: tint(@sidebar-bg, 60%);
			border-radius: 100%;
			float: left;
			img {
				border-radius: 100%;
			}
		}
		.user-info {
			margin-left: (12px + 40px);
			.name {
				font-weight: bold;
			}
		}
		ul.user-links {
			.list-unstyled;
			font-size: @font-size-sm;
			margin-top: 2px;
			li {
				float: left;
				margin-left: 8px;
				padding-left: 8px;
				border-left: 1px solid tint(@sidebar-bg, 10%);
				&:first-child {
					margin-left: 0;
					padding-left: 0;
					border-left: none;
				}
			}
			a {
				color: tint(@sidebar-bg, 40%);
				&:hover {
					color: tint(@sidebar-bg, 70%);
				}
			}
		}
	}

	ul.legal {
		padding-top: 8px;
		padding-bottom: 8px;
		.clearfix;
		font-size: 10px;
		background-color: shade(@sidebar-bg, 20%);
		color: tint(@sidebar-bg, 30%);
		li {
			float: left;
			margin-left: 8px;
			padding-left: 8px;
			border-left: 1px solid @sidebar-bg;
			&:first-child {
				margin-left: 0;
				padding-left: 0;
				border-left: none;
			}
		}
		a {
			color: tint(@sidebar-bg, 30%);
			&:hover {
				color: tint(@sidebar-bg, 60%);
			}
		}
	}

}

body {
	@media (min-width: @screen-sm) {
		margin-left: @sidebar-width-sm;
	}
	@media (min-width: @screen-md) {
		margin-left: @sidebar-width-md;
	}
	@media (min-width: @screen-lg) {
		margin-left: @sidebar-width-lg;
	}
}


// Mobile Menu Activation

@media (max-width: @screen-xs-max) {
	#page,
	#sidebar-main,
	#sidebar-footer,
	#sidebar-main > .inner,
	#sidebar-footer > .inner {
		.transition-transform(@mobile-sidebar-transition-duration ease-out);
	}
	#sidebar-main,
	#sidebar-footer {
		-webkit-transition: -webkit-transform @mobile-sidebar-transition-duration ease-out, visibility 0s linear @mobile-sidebar-transition-duration;
		   -moz-transition: -moz-transform @mobile-sidebar-transition-duration ease-out, visibility 0s linear @mobile-sidebar-transition-duration;
			 -o-transition: -o-transform @mobile-sidebar-transition-duration ease-out, visibility 0s linear @mobile-sidebar-transition-duration;
				transition: transform @mobile-sidebar-transition-duration ease-out, visibility 0s linear @mobile-sidebar-transition-duration;
	}
	body.sidebar-active {
		overflow: hidden;
		#page,
		#sidebar-main,
		#sidebar-footer {
			.translate3d(-@sidebar-width-xs, 0, 0);
		}
		#sidebar-main,
		#sidebar-footer {
			visibility: visible;
			.transition-delay(0s);
			> .inner {
				.translate3d(0, 0, 0);
			}
		}
	}
}



/*	Header
---------------------------------------------------------*/

#header {
	background-color: @header-bg;
	position: relative;
	// margin-bottom: 30px;
	@media (min-width: @screen-sm) {
		display: none;
	}
	> .container {
		> .inner {
			.clearfix;
		}
	}
}


// Branding

#header .site-branding {
	position: absolute;
	width: 100px;
	left: 50%;
	top: 4px;
	margin-left: -50px;
}


// Sidebar Toggle

#sidebar-toggle {
	&:extend(.btn.link all);
	float: right;
	padding: 10px 15px;
	border: none;
	margin-right: -15px;
	color: #fff;
	font-size: 24px;
	@media (min-width: @screen-sm) {
		display: none;
	}
	&:hover,
	&:focus {
		color: #fff;
	}
	&:before {
		.fa;
		content: @fa-var-bars;
	}
	.label {
		display: none;
	}
}



/*	Main
---------------------------------------------------------*/

#main {
	padding-bottom: 30px;
	.container {
		padding-top: 20px;
		padding-bottom: 20px;
		@media (min-width: @screen-sm) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}

.secondary-content {
	&.even {
		background-color: #fafafa;
	}
}

#primary {
	article.hentry {
		margin-bottom: (@line-height-computed * 2);
	}
}


/*	Page Templates
---------------------------------------------------------*/

body.home,
body.blog,
body.archive,
body.search,
body.error404,
body.single-post,
body.single-family_member,
body.single-event {
	@gutter-md: 30px;
	@gutter-lg: 40px;
	section.page-section.primary {
		> .container > .inner {
			.row(@screen-md, @gutter-md);
			.row(@screen-lg, @gutter-lg);
		}
		header.page-header-section {
			.col(@screen-md, 100%, @gutter-md);
			.col(@screen-lg, 100%, @gutter-lg);
			h1 {
				margin-bottom: (@line-height-computed * 1.5);
			}
		}
		.page-section-column.primary {
			.col(@screen-md, 65%, @gutter-md);
			.col(@screen-lg, 65%, @gutter-lg);
			margin-bottom: @line-height-computed;
			@media (min-width: @screen-md) {
				margin-bottom: 0;
			}
		}
		.page-section-column.secondary {
			.col(@screen-md, 35%, @gutter-md);
			.col(@screen-lg, 35%, @gutter-lg);
		}
	}
}
body.post-type-archive-family_member {
	section.page-section.primary {
		> .container > .inner {
			@media (min-width: @screen-md) {
				margin-left: 0;
				margin-right: 0;
			}
			@media (min-width: @screen-lg) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

body.home,
body.blog,
body.archive,
body.search, {
	.page-section-column.primary article.hentry {
		font-size: @font-size-base;
		margin-bottom: (@line-height-computed * 1);
		.entry-title {
			margin-top: 0;
		}
		&.has-post-thumbnail {
			&:extend(.clearfix all);
			padding-left: 65px;
			.post-thumbnail {
				float: left;
				margin-left: -65px;
				width: 50px;
			}
			@media (min-width: @screen-md) {
				padding-left: 115px;
				.post-thumbnail {
					margin-left: -115px;
					width: 100px;
				}
			}
			@media (min-width: @screen-lg) {
				padding-left: 170px;
				.post-thumbnail {
					margin-left: -170px;
					width: 150px;
				}
			}
		}
	}
}
body.single {
	.page-section-column.primary article.hentry {
		margin-bottom: (@line-height-computed * 2);
		.entry-title {
			display: none;
		}
		.entry-header .entry-meta {
			margin-top: 0;
		}
		footer.entry-meta {
			margin-top: @line-height-computed;
		}
	}
}

body.page {
	#main .page-thumbnail {
		display: none;
		float: right;
		margin: 0 0 (@line-height-computed / 2) @line-height-computed;
		max-width: 50%;
		padding: 4px;
		border: 1px solid #ccc;
		box-shadow: 0 2px 4px fadeout(#000, 90%);
		@media (min-width: @screen-sm) {
			display: block;
		}
		.wp-caption-text {
			text-align: center;
		}
	}
}


// Login
body.login {
	height: auto;
	min-height: 100%;
	margin-left: 0;
	background-color: @sidebar-bg;
	color: tint(@sidebar-bg, 50%);
	a {
		color: tint(@sidebar-bg, 70%);
		&:hover {
			color: #fff;
		}
	}
	#login {
		width: auto;
		max-width: 300px + 30px;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 8%;
		padding-bottom: 8%;
	}
	&.interim-login #login {
		margin-bottom: 0;
		margin-top: 0;
	}
	h1 {
		max-width: 250px;
		margin: 0 auto;
		a {
			.size(100%, 0);
			padding-bottom: percentage(381 / 500);
			background-image: url(../images/logos/baxley-connect.png);
			background-image: none, url(../images/logos/baxley-connect.svg);
			background-position: center center;
			-webkit-background-size: contain;
			background-size: contain;
		}
	}
	p.message,
	#login_error {
		a {
			color: inherit;
		}
	}
	p.message {
		.alert;
		.alert.info;
	}
	#login_error {
		.alert;
		.alert.danger;
	}
	#loginform,
	#lostpasswordform {
		border: 0;
		background-color: transparent;
		box-shadow: none;
		padding: 0;
		p {
			margin-bottom: (@line-height-computed / 2);
			&.forgetmenot {
				label {
					font-size: 12px;
				}
				input[type=checkbox] {
					.square(21px);
					margin-top: 0;
					margin-right: 4px;
					&:checked:before {
						font-size: 21px;
						margin: -1px -2px;
						color: tint(@sidebar-bg, 80%);
					}
				}
			}
			&.submit {
				clear: left;
				.button {
					.btn;
					.btn.lg;
					.btn-variant(#fff, @text-color, #fff);
					box-shadow: none;
					float: none;
					display: block;
					width: 100%;
					height: auto;
					&:active,
					&:focus {
						box-shadow: none;
					}
				}
			}
			input[type=text],
			input[type=password] {
				margin-top: 2px;
			}
		}
		label {
			display: block;
			color: tint(@sidebar-bg, 30%);
			font-size: 10px;
		}
		input[type=text],
		input[type=password],
		input[type=checkbox] {
			.form-control.lg;
			background-color: shade(@sidebar-bg, 10%);
			border-color: shade(@sidebar-bg, 25%);
			color: tint(@sidebar-bg, 80%);
			.placeholder(tint(@sidebar-bg, 20%));
			margin: 0;
			&:focus {
				box-shadow: none;
			}
		}
		button[type=submit] {
			.btn.lg;
			display: block;
			width: 100%;
			.btn-variant(#fff, @text-color, #fff);
		}
	}
	#nav {
		padding: 0;
		margin-top: 0;
		a {
			color: tint(@sidebar-bg, 30%);
			&:hover {
				color: tint(@sidebar-bg, 50%);
			}
		}
	}
	#backtoblog {
		display: none;
	}
}


// Family Member
body.single-family_member {
	.mobile-member-photo {
		margin-top: @line-height-computed;
		margin-bottom: (@line-height-computed / 2);
		@media (min-width: @screen-md) {
			display: none;
		}
		img {
			// margin: 0 auto;
			@media (min-width: @screen-sm) {
				margin: 0;
			}
		}
	}
	.page-section-column.secondary {
		.member-photo {
			display: none;
			@media (min-width: @screen-md) {
				display: block;
			}
		}
	}
}


// Address Book
body.address-book {
	nav.actions {
		margin-top: @line-height-computed;
	}
	.member-search {
		.clearfix;
		margin-top: @line-height-computed;
		margin-bottom: @line-height-computed;
		.search-form {
			@media (min-width: 600px) {
				float: left;
				width: 48%;
				margin-bottom: 0;
			}
		}
		.sorting-options {
			max-width: 300px;
			@media (min-width: 600px) {
				float: right;
				width: 48%;
				padding-left: 60px;
			}
			label {
				font-weight: 300;
				color: @text-muted;
				@media (min-width: 600px) {
					float: left;
					width: 50px;
					margin-left: -60px;
					text-align: right;
					margin-top: 10px;
				}
			}
		}
	}
}


// Tasks
body.post-type-archive-task {
	section.page-section.primary {
		.page-section-column.primary {
			.alert-container {
				display: none;
				padding-bottom: (@line-height-computed / 2);
				&.active {
					display: block;
				}
				.alert {
					margin: 0;
				}
			}
			article.task {
				> .inner {
					padding-left: 40px;
					position: relative;
					min-height: 30px + @line-height-computed;
					// padding-top: (@line-height-computed / 2);
					padding-bottom: (@line-height-computed / 2);
				}
				input.task-checkbox {
					-webkit-appearance: none;
					// -moz-appearance: none;
					// appearance: none;
					display: block;
					.square(24px);
					margin: 0;
					padding: 0;
					position: relative;
					background-color: @input-bg;
					background-image: none;
					border: 1px solid @input-border;
					border-radius: @input-border-radius;
					box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
					cursor: pointer;
					text-align: center;
					font-size: 16px;
					position: absolute;
					left: 0;
					top: 2px;
					&:checked {
						&:before {
							.fa;
							content: @fa-var-check;
							line-height: 24px - 2px;
							color: @link-color;
						}
					}
				}
			}
			footer.tasks-footer {
				padding-top: (@line-height-computed / 2);
				font-size: @font-size-sm;
				margin-bottom: @line-height-computed;
				> .inner {
					padding-top: (@line-height-computed / 2);
					border-top: 1px solid #ddd;
				}
				a {
					color: @text-muted;
					&:hover {
						color: @link-color;
					}
					&:after {
						.fa;
						content: @fa-var-angle-right;
						margin-left: 6px;
					}
				}
			}
		}
	}
}


// Events
body.single-event {
	.page-section-column.primary article.hentry {
		.entry-meta.meta-list {
			font-size: @font-size-base;
			color: @text-color;
			ul {
				margin: 0;
				li {
					margin: 0;
					padding: 0;
					border: none;
					float: none;
					margin-bottom: (@line-height-computed / 2);
					&.entry-date,
					&.entry-location {
						padding-left: 30px;
						&:before {
							.fa;
							.fa-fw;
							float: left;
							margin-left: -30px;
							margin-top: 3px;
							color: @brand-blue;
						}
					}
					&.entry-date:before { content: @fa-var-clock-o; }
					&.entry-location:before { content: @fa-var-map-marker; }
					&.entry-date {
						strong {
							min-width: 50px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}



/*	Comments
---------------------------------------------------------*/

#comments {
	margin-bottom: @line-height-computed;
}

.comment-list {
	&:extend(.list-unstyled);

	article.comment-body {
		&:extend(.clearfix all);
		margin-bottom: (@line-height-computed / 2);
		padding-left: 64px;
		.comment-meta {
			font-size: @font-size-sm;
			margin-bottom: (@line-height-computed / 4);
		}
		img.avatar {
			float: left;
			margin-left: -64px;
			width: 50px;
			border-radius: 100%;
		}
		.comment-content {
			p {
				margin-bottom: (@line-height-computed / 4);
			}
		}
		.comment-reply-link {
			font-size: @font-size-sm;
		}
	}

	.children {
		list-style: none;
		padding-left: 64px;
		article.comment-body {
			padding-left: 54px;
			img.avatar {
				margin-left: -54px;
				width: 40px;
			}
		}
		.children {
			padding-left: 54px;
		}
	}

}

.comment-respond {
	font-size: @font-size-base;
	input[type=submit] {
		.btn;
		.btn.primary;
	}
	.form-allowed-tags {
		display: none;
		code {
			display: block;
			white-space: normal;
			font-size: @font-size-sm;
		}
	}
}



/*	Widgets
---------------------------------------------------------*/

.widget {
	margin-bottom: @line-height-computed;
}
.widget-title {
	margin-top: 0;
	font-size: @font-size-sm;
	line-height: @line-height-base;
	color: @text-muted;
	text-transform: uppercase;
	margin-bottom: 6px;
}

.widget_recent_entries,
.widget_recent_comments,
.widget_archive,
.widget_categories {
	ul {
		.list-unstyled;
		font-size: @font-size-sm;
	}
	li {
		background-color: tint(@brand-navy-blue, 94%);
		padding: 6px 10px;
		&:nth-child(even) {
			background-color: tint(@brand-navy-blue, 88%);
		}
		a {
			color: @text-color;
			&:hover {
				color: @link-color;
			}
		}
	}
}

.widget_upcoming-events {
	h4.month {
		background-color: tint(@brand-navy-blue, 65%);
		color: #fff;
		font-size: @font-size-sm;
		line-height: @line-height-base;
		text-transform: uppercase;
		font-weight: 700;
		padding: 4px 10px;
		margin: 0;
	}
	ul {
		.list-unstyled;
		margin: 0;
	}
	li {
		a {
			display: block;
			.clearfix;
			color: @text-color;
			background-color: tint(@brand-navy-blue, 94%);
			padding: 6px 10px;
			padding-left: 60px;
			.transition(background-color 0.15s linear);
			&:hover {
				background-color: tint(@brand-navy-blue, 80%);
			}
		}
		&:nth-child(even) a {
			background-color: tint(@brand-navy-blue, 88%);
			&:hover {
				background-color: tint(@brand-navy-blue, 74%);
			}
		}
		span {
			display: block;
			&.entry-date {
				float: left;
				text-align: center;
				margin-left: -55px;
				width: 50px;
				span.date {
					font-size: 30px;
					line-height: 1.1;
				}
				span.day {
					font-size: 10px;
					text-transform: uppercase;
				}
			}
			&.entry-title {
				margin-top: 2px;
			}
			&.entry-excerpt {
				font-size: 10px;
				line-height: @line-height-sm;
				margin-bottom: 4px;
			}
		}
	}
}

.widget_upcoming-anniversaries {
	ul {
		.list-unstyled;
		margin: 0;
		font-size: @font-size-sm;
	}
	a {
		color: @text-color;
		&:hover {
			color: @link-color;
		}
	}
	> ul {
		> li {
			.clearfix;
			background-color: tint(@brand-navy-blue, 94%);
			padding: 6px 10px;
			padding-left: 60px;
			&:nth-child(even) {
				background-color: tint(@brand-navy-blue, 88%);
			}
			h4.month {
				font-size: 10px;
				float: left;
				margin: 0;
				margin-left: -50px;
				margin-top: 3px;
				width: 40px;
				text-transform: uppercase;
				font-weight: 700;
			}
			> ul {
				> li {
					margin-top: 4px;
					padding-right: 20px;
					position: relative;
					&:first-child {
						margin-top: 0;
					}
					&:before {
						.fa;
						.fa-fw;
						position: absolute;
						right: 0;
						top: 2px;
						color: tint(@brand-navy-blue, 65%);
					}
					&.birthday:before { content: @fa-var-birthday-cake; }
					&.anniversary:before { content: @fa-var-diamond; }
				}
			}
		}
	}
}

.widget_open-tasks {
	ul {
		.list-unstyled;
		margin: 0;
	}
	li {
		a {
			display: block;
			.clearfix;
			color: @text-color;
			background-color: tint(@brand-navy-blue, 94%);
			padding: 6px 10px;
			padding-left: 60px;
			.transition(background-color 0.15s linear);
			&:hover {
				background-color: tint(@brand-navy-blue, 80%);
			}
		}
		&:nth-child(even) a {
			background-color: tint(@brand-navy-blue, 88%);
			&:hover {
				background-color: tint(@brand-navy-blue, 74%);
			}
		}
		span {
			display: block;
			&.entry-date {
				float: left;
				margin-left: -50px;
				width: 40px;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 700;
				margin-top: 5px;
			}
			&.entry-excerpt {
				font-size: 10px;
				line-height: @line-height-sm;
				margin-bottom: 2px;
			}
		}
	}
}

.widget_member-search {
	ul {
		.list-unstyled;
		margin: 0;
	}
	li {
		a {
			display: block;
			color: @text-color;
			background-color: tint(@brand-navy-blue, 94%);
			padding: 6px 10px;
			.transition(background-color 0.15s linear);
			&:hover {
				background-color: tint(@brand-navy-blue, 80%);
			}
			> .inner {
				display: table;
				span {
					display: table-cell;
					vertical-align: middle;
					&.photo {
						width: 30px;
						padding-right: 8px;
						height: 22px;
						img {
							border-radius: 100%;
							width: 30px - 8px;
						}
					}
				}
			}
		}
		&:nth-child(even) a {
			background-color: tint(@brand-navy-blue, 88%);
			&:hover {
				background-color: tint(@brand-navy-blue, 74%);
			}
		}
	}
}



/*	Utilities
---------------------------------------------------------*/

.alignleft,
.alignright {
	margin-bottom: (@line-height-computed / 2);
}
.aligncenter {
	margin: 0 auto (@line-height-computed / 2);
}
@media (min-width: @screen-sm-min) {
	.alignleft,
	.alignright {
		max-width: 50%;
	}
	.alignleft {
		float: left;
		margin: 0 @line-height-computed (@line-height-computed / 2) 0;
	}
	.alignright {
		float: right;
		margin: 0 0 (@line-height-computed / 2) @line-height-computed;
	}
}



.doc-search {
	margin: 30px 0;
	.doc-search-form {
		display: flex;
		margin: 0 -10px;
		flex-wrap: wrap;
		.field {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			max-width: 300px;
			padding: 0 10px 20px;
			justify-content: space-between;
			label {
				width: 100%;
				display: block;
				margin-bottom: 4px;
			}
			select[name=ds_y],
			select[name=ds_m] {
				max-width: calc(49%);
			}
		}
		.form-submit {
			padding: 0 10px 20px;
			padding-top: 25px;
		}
	}
	.doc-search-results {
		margin-top: 20px;
		ul {
			list-style: none;
			padding: 0;
			li {
				margin-bottom: 14px;
				font-size: 12px;
				color: #aaa;
			}
			.title {
				display: block;
				// font-weight: 700;
				font-size: 16px;
			}
			.filesize,
			.date {
				&:before {
					content: '- ';
				}
			}
		}
	}
}