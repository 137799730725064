

/*	Sizing
---------------------------------------------------------*/

.size(@width, @height) {
	width: @width;
	height: @height;
}

.square(@width) {
	.size(@width, @width);
}


/*	One-Sided Border Radius
---------------------------------------------------------*/

.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	 border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	   border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	 border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	   border-top-left-radius: @radius;
}


/*	Vendor Prefixes
---------------------------------------------------------*/

// Box Sizing
.box-sizing(@boxmodel) {
	-webkit-box-sizing: @boxmodel;
	   -moz-box-sizing: @boxmodel;
	        box-sizing: @boxmodel;
}

// Animations
.animation(@animation) {
	-webkit-animation: @animation;
	     -o-animation: @animation;
	        animation: @animation;
}
.animation-name(@name) {
	-webkit-animation-name: @name;
	        animation-name: @name;
}
.animation-duration(@duration) {
	-webkit-animation-duration: @duration;
	        animation-duration: @duration;
}
.animation-timing-function(@timing-function) {
	-webkit-animation-timing-function: @timing-function;
	        animation-timing-function: @timing-function;
}
.animation-delay(@delay) {
	-webkit-animation-delay: @delay;
	        animation-delay: @delay;
}
.animation-iteration-count(@iteration-count) {
	-webkit-animation-iteration-count: @iteration-count;
	        animation-iteration-count: @iteration-count;
}
.animation-direction(@direction) {
	-webkit-animation-direction: @direction;
	        animation-direction: @direction;
}
.animation-fill-mode(@fill-mode) {
	-webkit-animation-fill-mode: @fill-mode;
	        animation-fill-mode: @fill-mode;
}

// Backface Visibility
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
	   -moz-backface-visibility: @visibility;
	        backface-visibility: @visibility;
}

// Input Placeholder
.placeholder(@color: @input-color-placeholder) {
	&::-moz-placeholder           { color: @color; opacity: 1; }
	&:-ms-input-placeholder       { color: @color; }
	&::-webkit-input-placeholder  { color: @color; }
}

// Transformations
.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	    -ms-transform: scale(@ratio);
	     -o-transform: scale(@ratio);
	        transform: scale(@ratio);
}
.scale(@ratioX; @ratioY) {
	-webkit-transform: scale(@ratioX, @ratioY);
	    -ms-transform: scale(@ratioX, @ratioY);
	     -o-transform: scale(@ratioX, @ratioY);
	        transform: scale(@ratioX, @ratioY);
}
.scaleX(@ratio) {
	-webkit-transform: scaleX(@ratio);
	    -ms-transform: scaleX(@ratio);
	     -o-transform: scaleX(@ratio);
	        transform: scaleX(@ratio);
}
.scaleY(@ratio) {
	-webkit-transform: scaleY(@ratio);
	    -ms-transform: scaleY(@ratio);
	     -o-transform: scaleY(@ratio);
	        transform: scaleY(@ratio);
}
.skew(@x; @y) {
	-webkit-transform: skewX(@x) skewY(@y);
	    -ms-transform: skewX(@x) skewY(@y);
	     -o-transform: skewX(@x) skewY(@y);
	        transform: skewX(@x) skewY(@y);
}
.translate(@x; @y) {
	-webkit-transform: translate(@x, @y);
	    -ms-transform: translate(@x, @y);
	     -o-transform: translate(@x, @y);
	        transform: translate(@x, @y);
}
.translate3d(@x; @y; @z) {
	-webkit-transform: translate3d(@x, @y, @z);
	        transform: translate3d(@x, @y, @z);
}
.rotate(@degrees) {
	-webkit-transform: rotate(@degrees);
	    -ms-transform: rotate(@degrees);
	     -o-transform: rotate(@degrees);
	        transform: rotate(@degrees);
}
.rotateX(@degrees) {
	-webkit-transform: rotateX(@degrees);
	    -ms-transform: rotateX(@degrees);
	     -o-transform: rotateX(@degrees);
	        transform: rotateX(@degrees);
}
.rotateY(@degrees) {
	-webkit-transform: rotateY(@degrees);
	    -ms-transform: rotateY(@degrees);
	     -o-transform: rotateY(@degrees);
	        transform: rotateY(@degrees);
}
.perspective(@perspective) {
	-webkit-perspective: @perspective;
	   -moz-perspective: @perspective;
	        perspective: @perspective;
}
.perspective-origin(@perspective) {
	-webkit-perspective-origin: @perspective;
	   -moz-perspective-origin: @perspective;
	        perspective-origin: @perspective;
}
.transform-origin(@origin) {
	-webkit-transform-origin: @origin;
	   -moz-transform-origin: @origin;
	    -ms-transform-origin: @origin;
	        transform-origin: @origin;
}

// Transitions
.transition(@transition) {
	-webkit-transition: @transition;
	     -o-transition: @transition;
	        transition: @transition;
}
.transition-property(@transition-property) {
	-webkit-transition-property: @transition-property;
	        transition-property: @transition-property;
}
.transition-delay(@transition-delay) {
	-webkit-transition-delay: @transition-delay;
	        transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
	-webkit-transition-duration: @transition-duration;
	        transition-duration: @transition-duration;
}
.transition-timing-function(@timing-function) {
	-webkit-transition-timing-function: @timing-function;
	        transition-timing-function: @timing-function;
}
.transition-transform(@transition) {
	-webkit-transition: -webkit-transform @transition;
	   -moz-transition: -moz-transform @transition;
	     -o-transition: -o-transform @transition;
	        transition: transform @transition;
}

// User Select
.user-select(@select) {
	-webkit-user-select: @select;
	   -moz-user-select: @select;
	    -ms-user-select: @select;
	        user-select: @select;
}

// Opacity
.opacity(@opacity) {
	opacity: @opacity;
	@opacity-ie: (@opacity * 100);
	filter: ~"alpha(opacity=@{opacity-ie})";
}


/*	Gradients
---------------------------------------------------------*/

#gradient {
	.horizontal(@start-color: #555; @end-color: #333; @start-percent: 0%; @end-percent: 100%) {
		background-image: -webkit-linear-gradient(left, color-stop(@start-color @start-percent), color-stop(@end-color @end-percent));
		background-image:  linear-gradient(to right, @start-color @start-percent, @end-color @end-percent);
		background-repeat: repeat-x;
		filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color),argb(@end-color)));
	}
	.vertical(@start-color: #555; @end-color: #333; @start-percent: 0%; @end-percent: 100%) {
		background-image: -webkit-linear-gradient(top, @start-color @start-percent, @end-color @end-percent);
		background-image: linear-gradient(to bottom, @start-color @start-percent, @end-color @end-percent);
		background-repeat: repeat-x;
		filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color),argb(@end-color)));
	}
	.directional(@start-color: #555; @end-color: #333; @deg: 45deg) {
		background-repeat: repeat-x;
		background-image: -webkit-linear-gradient(@deg, @start-color, @end-color);
		background-image: linear-gradient(@deg, @start-color, @end-color);
	}
	.horizontal-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
		background-image: -webkit-linear-gradient(left, @start-color, @mid-color @color-stop, @end-color);
		background-image: linear-gradient(to right, @start-color, @mid-color @color-stop, @end-color);
		background-repeat: no-repeat;
		filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color),argb(@end-color)));
	}
	.vertical-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
		background-image: -webkit-linear-gradient(@start-color, @mid-color @color-stop, @end-color);
		background-image: linear-gradient(@start-color, @mid-color @color-stop, @end-color);
		background-repeat: no-repeat;
		filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color),argb(@end-color)));
	}
	.radial(@inner-color: #555; @outer-color: #333) {
		background-image: -webkit-radial-gradient(circle, @inner-color, @outer-color);
		background-image: radial-gradient(circle, @inner-color, @outer-color);
		background-repeat: no-repeat;
	}
	.striped(@color: rgba(255,255,255,.15); @angle: 45deg) {
		background-image: -webkit-linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
		background-image: linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
	}
}

// Reset filters for IE
.reset-filter() {
	filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}


/*	Grid System
---------------------------------------------------------*/

// Grid Row
.row(@breakpoint: 0, @gutter: 30px) when (@breakpoint > 0) {
	@media (min-width: @breakpoint) {
		.row(0, @gutter);
	}
}
.row(@breakpoint: 0, @gutter: 30px) when (@breakpoint <= 0) {
	.clearfix();
	margin-left: -(@gutter / 2);
	margin-right: -(@gutter / 2);
}

// Grid Column
.col(@breakpoint: 0, @width: 100%, @gutter: 30px) when (@breakpoint > 0) {
	@media (min-width: @breakpoint) {
		.col(0, @width, @gutter);
	}
}
.col(@breakpoint: 0, @width: 100%, @gutter: 30px) when (@breakpoint <= 0) {
	float: left;
	width: @width;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);
}

// Grid Column Offset
.col-offset(@breakpoint: 0, @offset: 0) when (@breakpoint > 0) {
	@media (min-width: @breakpoint) {
		.col-offset(0, @offset);
	}
}
.col-offset(@breakpoint: 0, @offset: 0) when (@breakpoint <= 0) {
	margin-left: @offset;
}

// Grid Column Push/Pull
.col-push(@breakpoint: 0, @push: 0) when (@breakpoint > 0) {
	@media (min-width: @breakpoint) {
		.col-push(0, @push);
	}
}
.col-push(@breakpoint: 0, @push: 0) when (@breakpoint <= 0) {
	position: relative;
	left: @push;
}

// Default Grid System Setup
.gen-col(@size, @span, @breakpoint: 0) when (@span >= 0) and (@breakpoint > 0) {
	@media (min-width: @breakpoint) {
		.gen-col(@size, @span, 0);
	}
}
.gen-col(@size, @span, @breakpoint: 0) when (@span >= 0) and (@breakpoint <= 0) {
	.col.@{size}-@{span} {
		width: percentage(@span / @default-grid-columns);
	}
	.col.@{size}-offset-@{span} {
		margin-left: percentage(@span / @default-grid-columns);
	}
	.col.@{size}-push-@{span},
	.col.@{size}-pull-@{span} {
		position: relative;
	}
	.col.@{size}-push-@{span} {
		left: percentage(@span / @default-grid-columns);
	}
	.col.@{size}-pull-@{span} {
		left: -(percentage(@span / @default-grid-columns));
	}
	.gen-col(@size, (@span - 1), @breakpoint);
}


/*	Utilities
---------------------------------------------------------*/

// Clearfix
.clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}