

// Box sizing
* {
	.box-sizing(border-box);
}
*:before,
*:after {
	.box-sizing(border-box);
}

// Clearfix
.clearfix {
	.clearfix();
}

// Body Reset
html {
	font-size: @font-size-base;
	line-height: @line-height-base;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
body {
	font-family: @font-family-base;
	font-size: @font-size-base;
	line-height: @line-height-base;
	color: @text-color;
	font-weight: @font-weight-base;
	background-color: @body-bg;
	-webkit-text-size-adjust: 100%;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
}
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

// Links
a {
	color: @link-color;
	text-decoration: none;
	.transition(~"color linear .15s");
	&:hover,
	&:focus {
		color: @link-hover-color;
	}
}

// Figures
figure {
	margin: 0;
}

// Embeds
embed,
iframe,
object {
	max-width: 100%;
}

// Images
img {
	vertical-align: middle;
	display: block;
	max-width: 100%;
	width: auto;
	height: auto;
}

// Horizontal Rules
hr {
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
	border: 0;
	border-top: 1px solid @hr-border;
}


/*	Screen Readers
---------------------------------------------------------*/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}


/*	Containers
---------------------------------------------------------*/

.container {
	&:extend(.clearfix all);
	margin-right: auto;
	margin-left: auto;
	padding-left: @container-xs-gutter;
	padding-right: @container-xs-gutter;
	@media (min-width: @screen-sm-min) {
		max-width: @container-sm;
		padding-left: @container-sm-gutter;
		padding-right: @container-sm-gutter;
	}
	@media (min-width: @screen-md-min) {
		max-width: @container-md;
		padding-left: @container-md-gutter;
		padding-right: @container-md-gutter;
	}
	@media (min-width: @screen-lg-min) {
		max-width: @container-lg;
		padding-left: @container-lg-gutter;
		padding-right: @container-lg-gutter;
	}
}


/*	Grid System
---------------------------------------------------------*/

.row {
	.row(0, @default-grid-gutter);
}
.col {
	.col(0, 100%, @default-grid-gutter);
}
.gen-col(xs, @default-grid-columns, 0);
.gen-col(sm, @default-grid-columns, @screen-sm);
.gen-col(md, @default-grid-columns, @screen-md);
.gen-col(lg, @default-grid-columns, @screen-lg);


/*	Responsive Utilities
---------------------------------------------------------*/

// Visibility Utilities

.responsive-visibility() {
	display: block !important;
	table&   { display: table; }
	tr&      { display: table-row !important; }
	th&, td& { display: table-cell !important; }
}
.responsive-invisibility() {
	display: none !important;
}

@-ms-viewport {
	width: device-width;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	.responsive-invisibility();
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}

.visible-xs {              @media (max-width: @screen-xs-max) { .responsive-visibility(); } }
.visible-xs-block {        @media (max-width: @screen-xs-max) { display: block !important; } }
.visible-xs-inline {       @media (max-width: @screen-xs-max) { display: inline !important; } }
.visible-xs-inline-block { @media (max-width: @screen-xs-max) { display: inline-block !important; } }

.visible-sm {              @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .responsive-visibility(); } }
.visible-sm-block {        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { display: block !important; } }
.visible-sm-inline {       @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { display: inline !important; } }
.visible-sm-inline-block { @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { display: inline-block !important; } }

.visible-md {              @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .responsive-visibility(); } }
.visible-md-block {        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { display: block !important; } }
.visible-md-inline {       @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { display: inline !important; } }
.visible-md-inline-block { @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { display: inline-block !important; } }

.visible-lg {              @media (min-width: @screen-lg-min) { .responsive-visibility(); } }
.visible-lg-block {        @media (min-width: @screen-lg-min) { display: block !important; } }
.visible-lg-inline {       @media (min-width: @screen-lg-min) { display: inline !important; } }
.visible-lg-inline-block { @media (min-width: @screen-lg-min) { display: inline-block !important; } }

.hidden-xs { @media (max-width: @screen-xs-max) { .responsive-invisibility(); } }
.hidden-sm { @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .responsive-invisibility(); } }
.hidden-md { @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .responsive-invisibility(); } }
.hidden-lg { @media (min-width: @screen-lg-min) { .responsive-invisibility(); } }


// Print Utilities

.visible-print {
	.responsive-invisibility();
	@media print { .responsive-visibility(); }
}
.visible-print-block {
	display: none !important;
	@media print { display: block !important; } }
.visible-print-inline {
	display: none !important;
	@media print { display: inline !important; }
}
.visible-print-inline-block {
	display: none !important;
	@media print { display: inline-block !important; }
}

.hidden-print { @media print { .responsive-invisibility(); } }


/*	Screen Reading Utilities
---------------------------------------------------------*/

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	&:hover,
	&:active,
	&:focus {
		background-color: #f1f1f1;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: @brand-primary;
		display: block;
		font-size: @font-size-base;
		font-weight: bold;
		.square(auto);
		left: 5px;
		top: 5px;
		line-height: normal;
		padding: (@line-height-computed / 2) @line-height-computed;
		text-decoration: none;
		z-index: 100000;
	}
}