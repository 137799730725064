

/*	Normalize Non-Controls
---------------------------------------------------------*/

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: (@line-height-computed / 2);
	font-size: @font-size-sm;
	line-height: @line-height-base;
	color: @legend-color;
	border: 0;
	border-bottom: 1px solid @legend-border-color;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: (@line-height-computed / 4);
	font-weight: @label-font-weight;
	font-size: @font-size-base;
}


/*	Normalize Form Input Controls
---------------------------------------------------------*/

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea,
.form-control {
	display: block;
	width: 100%;
	height: @input-height-base;
	padding: @padding-base-vertical @padding-base-horizontal;
	font-size: @font-size-base;
	font-weight: @font-weight-base;
	line-height: @line-height-base;
	color: @input-color;
	background-color: @input-bg;
	background-image: none;
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
	
	&:focus {
		border-color: @input-border-focus;
		outline: none;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px fadeout(@input-border-focus, 50%);
	}

	.placeholder();

	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		cursor: not-allowed;
		background-color: @input-bg-disabled;
		opacity: 1;
	}

	&.sm {
		height: @input-height-sm;
		padding: @padding-sm-vertical @padding-sm-horizontal;
		font-size: @font-size-sm;
		line-height: @line-height-sm;
		border-radius: @border-radius-sm;
		select& {
			height: @input-height-sm;
			line-height: @input-height-sm;
		}
		textarea&,
		select[multiple]& {
			height: auto;
		}
	}

	&.lg {
		height: @input-height-lg;
		padding: @padding-lg-vertical @padding-lg-horizontal;
		font-size: @font-size-lg;
		line-height: @line-height-lg;
		border-radius: @border-radius-lg;
		select& {
			height: @input-height-lg;
			line-height: @input-height-lg;
		}
		textarea&,
		select[multiple]& {
			height: auto;
		}
	}

	.field.success & {
		border-color: @brand-success;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: darken(@brand-success, 10%);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px fadeout(@brand-success, 40%);
		}
	}

	.field.warning & {
		border-color: @brand-warning;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: darken(@brand-warning, 10%);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px fadeout(@brand-warning, 40%);
		}
	}

	.field.error & {
		border-color: @brand-danger;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		&:focus {
			border-color: darken(@brand-danger, 10%);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px fadeout(@brand-danger, 40%);
		}
	}

}

textarea {
	height: auto;
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select {
	white-space: nowrap;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	line-height: @input-height-base;
	line-height: @line-height-base ~"\0";
	&.sm {
		line-height: @input-height-sm;
	}
	&.lg {
		line-height: @input-height-lg;
	}
}

input[type="search"] {
	.box-sizing(border-box);
	-webkit-appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	&[disabled],
	&.disabled,
	fieldset[disabled] & {
		cursor: not-allowed;
	}
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: none;
}

output {
	display: block;
	padding-top: (@padding-base-vertical + 1);
	font-size: @font-size-base;
	line-height: @line-height-base;
	color: @input-color;
}


// Select2
.select2-container {
	width: 100% !important;
	body > & {
		width: auto !important;
	}
	.select2-selection {
		height: @input-height-base;
		border: 1px solid @input-border;
		border-radius: @input-border-radius;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
		.select2-selection__rendered {
			padding-top: @padding-base-vertical;
			padding-bottom: @padding-base-vertical;
			padding-left: @padding-base-horizontal;
			line-height: @line-height-base;
		}
		.select2-selection__arrow {
			height: (@input-height-base - 2px);
		}
	}
	&.select2-container--open {
		.select2-selection {
			border-color: #aaa;
		}
	}
}

// File Upload
.file-upload {
	button.select-file {
		position: relative;
		overflow: hidden;
		input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			opacity: 0;
			filter: alpha(opacity=0);
			transform: translate(-300px, 0) scale(4);
			font-size: 23px;
			direction: ltr;
			cursor: pointer;
		}
	}
	button.remove-file {
		.btn.link;
		display: none;
	}
	.preview {
		display: none;
		margin-bottom: (@line-height-computed / 4);
		max-width: 250px;
	}
	.progress {
		margin-top: (@line-height-computed / 4);
		height: 4px;
		background-color: #eee;
		border-radius: 100px;
		display: none;
		.progress-bar {
			height: 100%;
			border-radius: 100px;
			background-color: @brand-primary;
		}
	}
	&.selected {
		button.remove-file {
			display: inline-block;
		}
		.preview {
			display: block;
		}
	}
}

// Datepicker
.ui-datepicker {
	display: none;
	background-color: #fff;
	width: 250px;
	border: 1px solid shade(@input-border, 10%);
	border-radius: @input-border-radius;
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
	font-size: @font-size-base;
	line-height: @line-height-base;
	overflow: hidden;
}
.ui-datepicker-header {
	position: relative;
	height: 30px;
	text-align: center;
}
.ui-datepicker-prev,
.ui-datepicker-next {
	position: absolute;
	top: 0;
	font-size: 16px;
	line-height: 30px;
	cursor: pointer;
	.square(30px);
	text-align: center;
	&:before {
		.fa;
	}
	span {
		display: none;
	}
}
.ui-datepicker-prev {
	left: 0;
	&:before { content: @fa-var-angle-left; }
}
.ui-datepicker-next {
	right: 0;
	&:before { content: @fa-var-angle-right; }
}
.ui-datepicker-title {
	padding-top: 2px;
	line-height: 26px;
	select {
		.form-control.sm;
		display: inline-block;
		width: 90px;
		margin-left: 2px;
		&:first-child {
			margin-left: 0;
		}
	}
}
table.ui-datepicker-calendar {
	margin: 0;
	> thead {
		> tr {
			> th {
				font-weight: 300;
				text-transform: uppercase;
				color: #aaa;
				font-size: 10px;
				border-bottom: 1px solid #eee;
				padding: 6px 8px 4px;
			}
		}
	}
	> tbody {
		> tr {
			> td {
				border-top: none;
				padding: 0;
				> span,
				> a {
					display: block;
					padding: 6px 8px;
					.transition(~"background-color 0.15s linear, color 0.15s linear");
					&.ui-state-active {
						background-color: @link-color;
						color: #fff;
					}
				}
				> a {
					&:hover {
						background-color: tint(@link-color, 90%);
					}
				}
			}
		}
	}
	th, td {
		text-align: center;
	}
}


/*	Form Fields
---------------------------------------------------------*/

.field {
	margin-bottom: (@line-height-computed / 2);

	&.success {
		label,
		.help-block,
		.radio,
		.checkbox {
			color: @brand-success;
		}
	}

	&.warning {
		label,
		.help-block,
		.radio,
		.checkbox {
			color: @brand-warning;
		}
	}

	&.error {
		label,
		.help-block,
		.radio,
		.checkbox {
			color: @brand-danger;
		}
	}

}

// Checkboxes and Radios
.radio,
.checkbox {
	position: relative;
	display: block;
	min-height: @line-height-computed;
	margin-top: (@line-height-computed / 2);
	margin-bottom: (@line-height-computed / 2);
	label {
		padding-left: 20px;
		margin-bottom: 0;
		font-weight: @font-weight-base;
		cursor: pointer;
		font-size: @font-size-base;
	}
	&.disabled,
	fieldset[disabled] & {
		label {
			cursor: not-allowed;
		}
	}
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px \9;
}
.radio + .radio,
.checkbox + .checkbox {
	margin-top: -(@line-height-computed / 4);
}


/*	Static Input
---------------------------------------------------------*/

.static-input {
	padding-top: (@padding-base-vertical + 1);
	padding-bottom: (@padding-base-vertical + 1);
	margin-bottom: 0;
	&.lg,
	&.sm {
		padding-left: 0;
		padding-right: 0;
	}
}


/*	Input Help Blocks
---------------------------------------------------------*/

.input-help-block {
	display: block;
	margin-top: (@line-height-computed / 4);
	margin-bottom: (@line-height-computed / 4);
	color: @text-muted;
}