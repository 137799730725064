

.alert-variant(@bg, @border, @color) {
	background-color: @bg;
	border-color: @border;
	color: @color;
	hr {
		border-top-color: darken(@border, 5%);
	}
	.alert-link {
		color: darken(@color, 10%);
	}
}

.alert {
	padding: @padding-base-horizontal;
	margin-bottom: @line-height-computed;
	border: 1px solid transparent;
	border-radius: @border-radius-base;
	h4 {
		margin-top: 0;
		color: inherit;
	}
	a {
		font-weight: bold;
		&:hover {
			text-decoration: underline;
		}
	}
	> p,
	> ul {
		margin-bottom: 0;
	}
	> p + p {
		margin-top: 5px;
	}
	&.success { .alert-variant(tint(@brand-success, 90%), tint(@brand-success, 80%), @brand-success); }
	&.info    { .alert-variant(tint(@brand-info, 90%), tint(@brand-info, 80%), @brand-info); }
	&.warning { .alert-variant(tint(@brand-warning, 90%), tint(@brand-warning, 80%), @brand-warning); }
	&.danger  { .alert-variant(tint(@brand-danger, 90%), tint(@brand-danger, 80%), @brand-danger); }
}