@gform-gutter-width: 10px;

.gform_wrapper {
	margin-bottom: (@line-height-computed / 2);
	.clearfix();

	&.two-column_wrapper {
		@gutter: 30px;
		.gform_body {
			.row(@screen-sm-min, @gutter);
		}
		.gform_column {
			.col(@screen-sm-min, percentage(1 / 2), @gutter);
		}
		.gsection.gfrom_column,
		.gsection.empty {
			display: none;
		}
		.gform_footer.left_label {
			float: none;
			width: auto;
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.validation_error {
		&:extend(.alert, .alert.danger all);
	}

}

.gform_fields {
	.list-unstyled;
	margin: 0;

	&.left_label {
		@gutter: 20px;
		.gfield {
			.row(@screen-sm-min, @gutter);
			&.gsection {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.gfield_label {
			.col(@screen-sm-min, percentage(4 / 12), @gutter);
			.col(@screen-md-min, percentage(3 / 12), @gutter);
			@media (min-width: @screen-sm-min) {
				text-align: right;
			}
		}
		.ginput_container,
		.gfield_description {
			.col(@screen-sm-min, percentage(8 / 12), @gutter);
			.col(@screen-md-min, percentage(9 / 12), @gutter);
		}
		.gfield_description {
			.col-offset(@screen-sm-min, percentage(4 / 12));
			.col-offset(@screen-md-min, percentage(3 / 12));
		}
		@media (min-width: @screen-sm-min) {
			.gfield_label,
			.gfield_radio,
			.gfield_checkbox {
				padding-top: (@padding-base-vertical + 1);
			}
		}
		
	}

	&.description_above .gfield_description,
	.gfield.field_description_above .gfield_description {
		margin: -5px 0 5px;
		&.validation_message {
			margin-top: 5px;
			margin-bottom: 10px;
		}
	}

}

.gfield {
	.field;
	&.gfield_error {

		label,
		.validation_message {
			color: @brand-danger;
		}

		input[type="text"],
		input[type="password"],
		input[type="datetime"],
		input[type="datetime-local"],
		input[type="date"],
		input[type="month"],
		input[type="time"],
		input[type="week"],
		input[type="number"],
		input[type="email"],
		input[type="url"],
		input[type="search"],
		input[type="tel"],
		input[type="color"],
		textarea,
		select {
			border-color: @brand-danger;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
			&:focus {
				border-color: darken(@brand-danger, 10%);
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px fadeout(@brand-danger, 40%);
			}
		}

	}

	&.no-label {
		.gfield_label {
			display: none;
		}
	}

}

.gfield_required {
	color: @brand-danger;
	margin-left: 1px;
}

.gform_description {
	display: block;
	margin: 0 0 (@line-height-computed / 2);
}

.ginput_complex {
	.clearfix;
	label {
		font-size: @font-size-sm;
		font-weight: @font-weight-base;
		margin: 0;
	}
}
.ginput_full,
.ginput_left,
.ginput_right {
	display: block;
	margin-bottom: 5px;
}
@media (min-width: @screen-sm-min) {
	@gutter: 20px;
	.ginput_full {
		clear: both;
	}
	.ginput_left,
	.ginput_right {
		width: 50%;
	}
	.ginput_left {
		float: left;
		clear: left;
		padding-right: (@gutter / 2);
	}
	.ginput_right {
		float: right;
		clear: right;
		padding-left: (@gutter / 2);
	}
}

.gfield_radio li { .radio; }
.gfield_checkbox li { .checkbox; }
.gfield_radio,
.gfield_checkbox {
	.list-unstyled;
	margin: 0;
	li {
		margin-top: 0;
		margin-bottom: 5px;
		padding-left: 20px;
		label {
			padding-left: 0;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		float: left;
		margin-left: -20px;
	}
}

.gfield_description {
	.input-help-block;
	font-size: @font-size-sm;
	& + &,
	.ginput_complex + & {
		margin-top: -5px;
	}
}

.gsection_title {
	margin-bottom: @line-height-computed;
	font-size: (@font-size-base * 1.5);
	line-height: inherit;
	color: @legend-color;
	border-bottom: 1px solid @legend-border-color;
}

.gform_footer {
	&.left_label {
		@gutter: 20px;
		.row(@screen-sm-min, @gutter);
		button[type=submit] {
			@media (min-width: @screen-sm-min) {
				margin-left: percentage(4 / 12);
				margin-right: 10px;
				position: relative;
				left: (@gutter / 2);
			}
			@media (min-width: @screen-md-min) {
				margin-left: percentage(3 / 12);
			}
		}
	}
}

.gform_ajax_spinner {
	display: inline-block;
	margin-left: 10px;
}

#gforms_confirmation_message {
	&:extend(.alert, .alert.success all);
}